import Vue from "vue";
import Vuex from "vuex";

import modules from "./modules";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    message: "",
    previous_link: "",
    previous_page: "",
    search_value: "",
    uploadedImage: "",
    uploadedDproduct:"",
    LoginData: {},
  },
  mutations: {
    saveData(state, value) {
      state.LoginData = value;
      //localStorage.setItem('data',JSON.stringify(value))
    },
    savePreviosLink(state, value) {
      state.previous_link = value;
    },
    savePreviosPage(state, value) {
      state.previous_page = value;
    },
    saveSearch(state, value) {
      state.search_value = value;
    },
    dataRefresh(state) {
      if (localStorage.getItem("data") !== null) {
        state.LoginData = JSON.parse(localStorage.getItem("data"));
      }
    },
    setUploadedImage(state, image) {
      state.uploadedImage = image;
    },
    setUploadedDproduct(state, value) {
      state.uploadedDproduct = value;
    },
  },
  actions: {
    uploadImage({ commit }, image) {
      commit("setUploadedImage", image); // Action to commit the mutation
    },
    storeDproduct({ commit }, value) {
      commit("setUploadedDproduct", value); // Action to commit the mutation
    },
  },
  getters: {
    uploadedImage: (state) => state.uploadedImage, // Getter to access the uploaded image
    uploadedDproduct: (state) => state.uploadedDproduct, 
  },
  watch: {},
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html

  strict: process.env.NODE_ENV !== "production",
});

export default store;
