import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'
import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import InfiniteLoading from 'vue-infinite-loading';
import VueLazyload from 'vue-lazyload'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'

Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import { BSidebar } from 'bootstrap-vue'
import axios from 'axios';

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});

axios.defaults.headers.common["current-url"] = window.location;

import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  color: '#e74c3c',
  failedColor: 'red',
  height: '5px'
})
import appConfig from "@/app.config";
if (appConfig.backend_debug == false) {
console.log = function() {}
}

import '@/assets/scss/app.scss'
Vue.use('b-sidebar', BSidebar)
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(require("vue-chartist"));
Vue.use(VueMask);
Vue.use(Toast);
Vue.use(InfiniteLoading);
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)

// Use VueLazyload
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: 'dist/error.png',
  loading: require('@/assets/loading.gif'),
  attempt: 1,
  observer: true, // Enable Intersection Observer
  observerOptions: {
    rootMargin: '0px',
    threshold: 0.1
  }
});

Vue.config.productionTip = false;

Vue.config.devtools = false
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
